/*-----------------------------------------------
|   Reboot
-----------------------------------------------*/
::selection {
  color: $white;
  background-color: map_get($theme-colors, dark);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

section {
  position: relative;
  padding-top: map_get($spacers, 6);
  padding-bottom: map_get($spacers, 6);
  @include media-breakpoint-up(lg){
    padding-top: map_get($spacers, 8);
    padding-bottom: map_get($spacers, 8);
  }
}

//setting up font family for bootstrap components
input,
button,
select,
optgroup,
textarea,
label,
.alert,
.badge,
.blockquote-footer,
.btn,
.navbar,
.pagination,
.valid-feedback,
.invalid-feedback { font-family: $font-family-sans-serif; }
