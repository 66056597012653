#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #1a1b23;
    z-index: 10000;
  }
  
  .loader {
    position: absolute;
    width: 42px;
    height: 42px;
    -webkit-animation: preloader_6 5s infinite linear;
    animation: preloader_6 5s infinite linear;
    left: 50%;
    top: 50%;
    margin-left: -21px;
    margin-top: -21px;
    span {
      width: 20px;
      height: 20px;
      position: absolute;
      background: #B21329;
      display: block;
      -webkit-animation: preloader_6_span 1s infinite linear;
      animation: preloader_6_span 1s infinite linear;
      &:nth-child(1) {
        background: #B21329;
      }
      &:nth-child(2) {
        left: 22px;
        background: #68288B;
        -webkit-animation-delay: .2s;
        animation-delay: .2s;
      }
      &:nth-child(3) {
        top: 22px;
        background: #00BA7B;
        -webkit-animation-delay: .4s;
        animation-delay: .4s;
      }
      &:nth-child(4) {
        top: 22px;
        left: 22px;
        background: #CC813F;
        -webkit-animation-delay: .6s;
        animation-delay: .6s;
      }
    }
  }
  
  @-webkit-keyframes preloader_6_span {
    0%, 100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  
    50% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
  }
  
  
  @keyframes preloader_6_span {
    0%, 100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  
    50% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
  }