/*-----------------------------------------------
|   Buttons
-----------------------------------------------*/
.btn {
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: $font-size-sm;
  line-height: 1;
}

.btn-link{font-weight: $font-weight-bold;}

.btn.btn-outline-light.border-2x { border-color: rgba($white, 0.5); }

.btn-outline-light:hover, .btn-light, .btn-light:hover { color: $dark; }
.btn-xs{ padding: .2rem .8rem; }
.btn-lg{ font-size: $font-size-lg; }
.btn-close{
  position: fixed;
  top: 0;
  right: 0;
  height: 48px;
  width: 48px;
  margin-top: 1.8rem;
  margin-right: 1.8rem;
  border-radius: 50%;
  z-index: 9999;
  background-color: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  line-height: 48px;
  text-align: center;
  transition: top 0.2s linear, right 0.2s linear, opacity 0.1s linear;
  cursor: pointer;
}
@include media-breakpoint-up(lg) { 
  .btn-close{
    background-color: transparent;
    box-shadow: none;
  }
}